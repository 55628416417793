import PublicDashboardTitle from "../Components/Title";
import PublicDashboardMono from "./Mono";

import PublicDashboardCardTable from "../Components/CardTable";
import PublicDashboardOrderTable from "../Components/PaymentTable";
import PublicDashboardCardLogs from "../Components/CardLogs";
// import PublicDashboardRefPaymentTable from "../Components/RefPaymentTable";
import { useContext } from "react";
import AuthContext from '../../../../context/auth';
import AdsComponent from '../../../../components/GoogleAds/GoogleAds';


import { InfoAlert } from "../../../../components/Alert/Alert";


function PublicDashboard() {

    const { user } = useContext(AuthContext);

    return (

        <>
            <PublicDashboardTitle title={"Dashboard"} icon={"bi bi-house-door fa-fw me-1"} addcardbtn={true} />

            <InfoAlert title={"Update"} content={
                [
                `We've recently updated our website to remove all copyrighted material and logos from state and central authorities.`,
                `Funfact: We're now taking on the responsibility of ensuring your tummy stays full while you work hard. 
                    Our new images will serve as a gentle reminder to prioritize good eating habits even during busy times.`
                ]
            } close={false}/>


            <PublicDashboardMono />

            <PublicDashboardCardLogs />

            <PublicDashboardCardTable />

            <div className="col-12 mb-4">
                <AdsComponent dataAdSlot="5824067931" />
            </div>

            {
                (!user.acquired_by && !user.is_subadmin) && <PublicDashboardOrderTable />
            }


        </>

    );
}


export default PublicDashboard;