import { Link } from "react-router-dom";
import { useMutationSendWebhook } from "../../../api/webhook";

import { getRandomColor } from "../../../helper/Color";

import AdsComponent from "../../../components/GoogleAds/GoogleAds";

function PublicContactPage() {

	const { mutateAsync, isLoading } = useMutationSendWebhook();

	async function onsubmit(e) {
		e.preventDefault();
		mutateAsync({
			embeds: [
				{
					title: "Website Contact Form | ID Maker",
					description: "A new contact form submission has been received.",
					url: "https://idcard.store/contact",
					color: getRandomColor(),
					fields: [
						{
							name: "Name",
							value: e.target.name.value,
							inline: true,
						},
						{
							name: "Email",
							value: e.target.email.value,
							inline: true,
						},
						{
							name: "Message",
							value: e.target.message.value,
							inline: false,
						},
						{
							name: "Phone",
							value: e.target.phone.value,
							inline: true,
						},
						{
							name: "Reason",
							value: e.target.reason.value,
							inline: true,
						},

					],
					timestamp: new Date().toISOString(),
				},
			],
		});

	}

	return (
		<>
			<section className="pt-4 pt-md-5">
				<div className="container">
					<div className="row mb-5">
						<div className="col-xl-10">
							{/* <!-- Title --> */}
							<h1>Get in Touch</h1>
							<p className="lead mb-0">At ID Maker, we are committed to providing you with exceptional support to ensure your experience with our products and services is smooth and hassle-free. If you have any questions, concerns, or require assistance, please don't hesitate to reach out to our dedicated support team.</p>
						</div>
					</div>

					{/* <!-- Contact info --> */}
					<div className="row g-4">

						{/* <!-- Contact item START --> */}
						<div className="col-md-6 col-xl-4">
							<div className="card card-body shadow text-center align-items-center h-100">
								{/* <!-- Icon --> */}
								<div className="icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-2"><i className="bi bi-headset fs-5"></i></div>
								{/* <!-- Title --> */}
								<h5>Call us</h5>
								<p>Questions or need assistance? Call us, Monday to Saturday, 10 AM - 5 PM.</p>
								{/* <!-- Buttons --> */}
								<div className="d-grid gap-3 d-sm-block">
									<Link to="tel:+918247834320"><button className="btn btn-primary-soft me-1"><i className="bi bi-telephone me-2"></i>8247834320</button></Link>
									<Link to="https://api.whatsapp.com/send?phone=918989794676&text=Hey, I need some help" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary-soft ms-1"><i className="bi bi-whatsapp me-2"></i>8989794676</button></Link>
								</div>
							</div>
						</div>
						{/* <!-- Contact item END --> */}

						{/* <!-- Contact item START --> */}
						<div className="col-md-6 col-xl-4">
							<div className="card card-body shadow text-center align-items-center h-100">
								{/* <!-- Icon --> */}
								<div className="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-2"><i className="bi bi-inboxes-fill fs-5"></i></div>
								{/* <!-- Title --> */}
								<h5>Email us</h5>
								<p>If you prefer to communicate through email, you can reach out to us at the following address:</p>
								{/* <!-- Buttons --> */}
								<Link to="mailto:support@idcard.store" className="btn btn-link text-decoration-underline p-0 mb-0"><i className="bi bi-envelope me-1"></i>support@idcard.store</Link>
							</div>
						</div>
						{/* <!-- Contact item END --> */}

						{/* <!-- Contact item START --> */}
						<div className="col-xl-4 position-relative">
							{/* <!-- Svg decoration --> */}
							<figure className="position-absolute top-0 end-0 z-index-1 mt-n4 ms-n7">
								<svg className="fill-warning" width="77px" height="77px">
									<path d="M76.997,41.258 L45.173,41.258 L67.676,63.760 L63.763,67.673 L41.261,45.171 L41.261,76.994 L35.728,76.994 L35.728,45.171 L13.226,67.673 L9.313,63.760 L31.816,41.258 L-0.007,41.258 L-0.007,35.725 L31.816,35.725 L9.313,13.223 L13.226,9.311 L35.728,31.813 L35.728,-0.010 L41.261,-0.010 L41.261,31.813 L63.763,9.311 L67.676,13.223 L45.174,35.725 L76.997,35.725 L76.997,41.258 Z"></path>
								</svg>
							</figure>

							<div className="card card-body shadow text-center align-items-center h-100">
								{/* <!-- Icon --> */}
								<div className="icon-lg bg-orange bg-opacity-10 text-orange rounded-circle mb-2"><i className="bi bi-globe2 fs-5"></i></div>
								{/* <!-- Title --> */}
								<h5>Online Groups</h5>
								<p>Stay Informed and Engage in ID Maker community</p>
								{/* <!-- Buttons --> */}
								<ul className="list-inline mb-0">
									<Link to="https://chat.whatsapp.com/EoiRd94r6Bc09409JPDsB8" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary-soft me-2"><i className="bi bi-whatsapp me-2"></i>Join</button></Link>
									<Link to="https://t.me/idcardsupport" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary-soft ms-2"><i className="bi bi-telegram me-2"></i>Join</button></Link>
								</ul>
							</div>
						</div>
						{/* <!-- Contact item END --> */}
					</div>
				</div>
			</section>

			<section className="pt-4 pt-md-5">
				<div className="container">
					<AdsComponent dataAdSlot="5714913101" />
				</div>
			</section>

			<section className="pt-0 pt-lg-5">
				<div className="container">
					<div className="row g-4 g-lg-5 align-items-center">
						{/* <!-- Vector image START --> */}
						<div className="col-lg-6 text-center">
							<img src="/static/assets/images/element/5124556.png" alt="" />
						</div>
						{/* <!-- Vector image END --> */}

						{/* <!-- Contact form START --> */}
						<div className="col-lg-6">
							<div className="card bg-light p-4">
								{/* <!-- Svg decoration --> */}
								<figure className="position-absolute end-0 bottom-0 mb-n4 me-n2">
									<svg className="fill-orange" width="104.2px" height="95.2px">
										<circle cx="2.6" cy="92.6" r="2.6" />
										<circle cx="2.6" cy="77.6" r="2.6" />
										<circle cx="2.6" cy="62.6" r="2.6" />
										<circle cx="2.6" cy="47.6" r="2.6" />
										<circle cx="2.6" cy="32.6" r="2.6" />
										<circle cx="2.6" cy="17.6" r="2.6" />
										<circle cx="2.6" cy="2.6" r="2.6" />
										<circle cx="22.4" cy="92.6" r="2.6" />
										<circle cx="22.4" cy="77.6" r="2.6" />
										<circle cx="22.4" cy="62.6" r="2.6" />
										<circle cx="22.4" cy="47.6" r="2.6" />
										<circle cx="22.4" cy="32.6" r="2.6" />
										<circle cx="22.4" cy="17.6" r="2.6" />
										<circle cx="22.4" cy="2.6" r="2.6" />
										<circle cx="42.2" cy="92.6" r="2.6" />
										<circle cx="42.2" cy="77.6" r="2.6" />
										<circle cx="42.2" cy="62.6" r="2.6" />
										<circle cx="42.2" cy="47.6" r="2.6" />
										<circle cx="42.2" cy="32.6" r="2.6" />
										<circle cx="42.2" cy="17.6" r="2.6" />
										<circle cx="42.2" cy="2.6" r="2.6" />
										<circle cx="62" cy="92.6" r="2.6" />
										<circle cx="62" cy="77.6" r="2.6" />
										<circle cx="62" cy="62.6" r="2.6" />
										<circle cx="62" cy="47.6" r="2.6" />
										<circle cx="62" cy="32.6" r="2.6" />
										<circle cx="62" cy="17.6" r="2.6" />
										<circle cx="62" cy="2.6" r="2.6" />
										<circle cx="81.8" cy="92.6" r="2.6" />
										<circle cx="81.8" cy="77.6" r="2.6" />
										<circle cx="81.8" cy="62.6" r="2.6" />
										<circle cx="81.8" cy="47.6" r="2.6" />
										<circle cx="81.8" cy="32.6" r="2.6" />
										<circle cx="81.8" cy="17.6" r="2.6" />
										<circle cx="81.8" cy="2.6" r="2.6" />
										<circle cx="101.7" cy="92.6" r="2.6" />
										<circle cx="101.7" cy="77.6" r="2.6" />
										<circle cx="101.7" cy="62.6" r="2.6" />
										<circle cx="101.7" cy="47.6" r="2.6" />
										<circle cx="101.7" cy="32.6" r="2.6" />
										<circle cx="101.7" cy="17.6" r="2.6" />
										<circle cx="101.7" cy="2.6" r="2.6" />
									</svg>
								</figure>

								{/* <!-- Card header --> */}
								<div className="card-header bg-light p-0 pb-3">
									<h3 className="mb-0">Send us message</h3>
								</div>

								{/* <!-- Card body START --> */}
								<div className="card-body p-0">
									<form className="row g-4" onSubmit={onsubmit}>
										{/* <!-- Name --> */}
										<div className="col-md-6">
											<label className="form-label">Your name <span className="text-danger">*</span></label>
											<input type="text" name="name" className="form-control" required />
										</div>
										{/* <!-- Email --> */}
										<div className="col-md-6">
											<label className="form-label">Email address <span className="text-danger">*</span></label>
											<input type="email" name="email" className="form-control" required />
										</div>
										{/* <!-- Mobile number --> */}
										<div className="col-6">
											<label className="form-label">Mobile number <span className="text-danger">*</span></label>
											<input name="phone" type="text" className="form-control" required />
										</div>
										{/* Contact Type */}
										<div className="col-6">
											<label className="form-label">Reason <span className="text-danger">*</span></label>
											<select className="form-select js-choice" name="reason" required>
												<option value="Account">Account</option>
												<option value="Payments">Payments</option>
												<option value="Feature Request">Feature Request</option>
												<option value="Business">Business</option>
												<option value="Other">Other</option>
											</select>
										</div>
										{/* <!-- Message --> */}
										<div className="col-12">
											<label className="form-label">Message <span className="text-danger">*</span></label>
											<textarea name="message" className="form-control" rows={3} required></textarea>
										</div>
										{/* <!-- Checkbox --> */}
										<div className="col-12 form-check ms-2">
											<input type="checkbox" className="form-check-input" id="exampleCheck1" required />
											<label className="form-check-label" htmlFor="exampleCheck1">
												By submitting this form you agree to our terms and conditions.
											</label>
										</div>
										{/* <!-- Button --> */}
										<div className="col-12">
											<button className="btn btn-dark mb-0" disabled={isLoading}>
												<span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: !isLoading ? 'None' : "" }}></span>
												Send Message
											</button>
										</div>
									</form>
								</div>
								{/* <!-- Card body END --> */}
							</div>
						</div>
						{/* <!-- Contact form END --> */}
					</div>
				</div>
			</section>



			<section className="pt-0 pt-lg-5">
				<div className="container">
					<div className="row g-4">
						{/* <!-- Map --> */}
						<div className="col-sm-6 col-lg-4 mx-auto">
							<iframe className="w-100 h-100 rounded-3 border-0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14384.13342076694!2d78.4580201!3d25.670189!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3977121a62b95853%3A0x29230d64e7f7ac0d!2sID%20Maker%20(MayankFawkes)!5e0!3m2!1sen!2sin!4v1712072569537!5m2!1sen!2sin" height="500" aria-hidden="false" tabIndex={0}></iframe>
						</div>

						{/* <!-- Address item --> */}
						<div className="col-sm-6 col-lg-4 mx-auto">
							<div className="card card-body shadow p-4 h-100">
								<h5 className="mb-3">MP, India</h5>
								<address className="mb-1">Mayank Gupta, Bihari ji road, Datia, Madhya Pradesh 475661</address>
								<p className="mb-1">Call: +91-82478-34320 </p>
								<p className="mb-1">Whatsapp: +91-89897-94676 </p>
								<p>Support time: Monday to Saturday
									<br/>
										10:00 am to 6:00 pm
								</p>
							</div>
						</div>

					</div>
				</div>
			</section>
		</>
	);
}


export default PublicContactPage;