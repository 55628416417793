function PageMakerFunc(canvas: HTMLCanvasElement, form: HTMLFormElement) {
	const ctx = canvas.getContext("2d");

    if (!ctx) return console.log("CTX is null");

	const border = 'true' == form.border.value;

    const layout = {
        width: form.width.value,
        height: form.height.value,
        topMargin: form.topMargin.value,
        cardXSpacing: form.cardXSpacing.value,
        cardYSpacing: form.cardYSpacing.value,
    }

    canvas.height = 3508;
	canvas.width = 2480;

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // increase card width and height by percentage
    const CARD_WIDTH = 1016 + (1016 * layout.width/100);
    const CARD_HEIGHT = 638 + (638 * layout.height/100);

    var a = Array<Element | HTMLInputElement>(...form.querySelectorAll('input[type="file"]'));
	var files: [Element, Element][] = []
	while (a.length > 0){
        files.push([a.shift() as Element, a.shift() as Element]);
	}

    const BETWEEN_CARD_X = 10 + (10 * layout.cardXSpacing);
    const BETWEEN_CARD_Y = 15 + (15 * layout.cardYSpacing);

    let TOP_PADDING = ((canvas.height - (CARD_HEIGHT*5 + BETWEEN_CARD_Y*4))/2); // sub space between cards - half
    const SIDE_PADDING = ((canvas.width - (CARD_WIDTH*2 + BETWEEN_CARD_X))/2); // sub space between cards - half

    TOP_PADDING = Math.min(TOP_PADDING, TOP_PADDING-(layout.topMargin * 15 ) );

    console.log("TOP_PADDING", TOP_PADDING);
    console.log("SIDE_PADDING", SIDE_PADDING);

    var pos = 0;

    for (const [front, back] of files) {
        let start_from = TOP_PADDING + (pos * (CARD_HEIGHT + BETWEEN_CARD_Y));

        if (front instanceof HTMLInputElement && front.files?.length) {
            let img_img = new Image();
            img_img.src = URL.createObjectURL(front.files[0]);
            img_img.onload = function() {
                ctx.drawImage(img_img, SIDE_PADDING, start_from, CARD_WIDTH, CARD_HEIGHT);
                if (border) {
                    ctx.strokeRect(SIDE_PADDING, start_from, CARD_WIDTH, CARD_HEIGHT);
                }
            }
        }
        if (back instanceof HTMLInputElement && back.files?.length) {
            let img_img = new Image();
            img_img.src = URL.createObjectURL(back.files[0]);
            img_img.onload = function() {
                ctx.drawImage(img_img, SIDE_PADDING + CARD_WIDTH + BETWEEN_CARD_X, start_from, CARD_WIDTH, CARD_HEIGHT);
                if (border) {
                    ctx.strokeRect(SIDE_PADDING + CARD_WIDTH + BETWEEN_CARD_X, start_from, CARD_WIDTH, CARD_HEIGHT);
                }
            }
        }
        pos += 1;
    }

    return true;

}


export default PageMakerFunc;