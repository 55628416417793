import PublicDashboardTitle from "../Components/Title";
import { PublicDashboardIframeCanvas } from "../Components/PageElements";
import { useRef, useState } from "react";

import useDebounce from "../../../../helper/utils";

import PageMakerFunc from "../../../../helper/PageMakerFn";
import AdsComponent from "../../../../components/GoogleAds/GoogleAds";

function PublicDashboardPageMaker() {
    const [layout, setLayout] = useState({
        width: 0,
        height: 0,
        topMargin: 0,
        cardXSpacing: 1,
        cardYSpacing: 1,
    });

    const canvasRef = useRef() as React.MutableRefObject<HTMLCanvasElement>;
    const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;

    const onChange = useDebounce(async () => {

        PageMakerFunc(canvasRef.current, formRef.current);

        setLayout({
            width: formRef.current.width.value,
            height: formRef.current.height.value,
            topMargin: formRef.current.topMargin.value,
            cardXSpacing: formRef.current.cardXSpacing.value,
            cardYSpacing: formRef.current.cardYSpacing.value,
        });

    }, 250); // 100ms debounce delay


    // useEffect(() => {
    //     console.log("Layout", layout);
    // }, [layout])

    return (
        <>
            <PublicDashboardTitle title={"Free Page Maker"} icon={"bi bi-file-earmark-fill fa-fw me-1"} addcardbtn={true} />

            <div className="row g-4">
                <div className="col-md-7">
                    <div className="card border">
                        <div className="card-header border-bottom">
                            <h5 className="card-header-title">Page Maker</h5>
                            <p className="mb-0 small">Here you can set front and back of your card to an A4 sheet within a second.</p>
                        </div>
                        {/* <!-- Card body START --> */}
                        <form onChange={onChange} ref={formRef}>
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Front</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Back</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file1" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Front</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file2" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Back</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file3" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Front</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file4" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Back</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file5" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Front</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file6" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Back</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file7" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Front</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file8" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="formFileSm" className="form-label d-none d-sm-block">Back</label>
                                            <input className="form-control form-control" id="formFileSm" type="file" name="file9" />
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Border</label>
                                    <select id="" className="form-select js-choice" name="border" required>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </div>

                                <div className="row">

                                    {/* Height and Width */}
                                    <div className="col">
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <div className="d-flex justify-content-between">
                                                    <label className="form-label">Height</label>
                                                    <div className="text-muted small">{layout.height}%</div>
                                                </div>
                                                <input name="height" type="range" className="form-range" min="0" max="5" step="1" defaultValue="0" required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <div className="d-flex justify-content-between">
                                                    <label className="form-label">Width</label>
                                                    <div className="text-muted small">{layout.width || 0}%</div>
                                                </div>
                                                <input name="width" type="range" className="form-range" min="0" max="5" step="1" defaultValue="0" required />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Margin top */}
                                    <div className="col">
                                        <div className="mb-3">
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label">Top Margin</label>
                                                <div className="text-muted small">{layout.topMargin}%</div>
                                            </div>
                                            <input name="topMargin" type="range" className="form-range" min="0" max="5" step="1" defaultValue="0" required />
                                        </div>
                                    </div>


                                    {/* Card X and Y spacing */}
                                    <div className="col">
                                        <div className="mb-3">
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label">Card Space X</label>
                                                <div className="text-muted small">{layout.cardXSpacing}%</div>
                                            </div>
                                            <input name="cardXSpacing" type="range" className="form-range" min="0" max="5" step="1" defaultValue={layout.cardXSpacing} required />
                                        </div>
                                        <div className="mb-3">
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label">Card Space Y</label>
                                                <div className="text-muted small">{layout.cardYSpacing}%</div>
                                            </div>
                                            <input name="cardYSpacing" type="range" className="form-range" min="0" max="5" step="1" defaultValue={layout.cardYSpacing} required />
                                        </div>
                                    </div>

                                </div>


                            </div>


                        </form>
                        {/* <!-- Card body END --> */}
                    </div>
                </div>

                <PublicDashboardIframeCanvas innerRef={canvasRef} title="Page Maker PDF file" description="This is A4 size PDF file." />

                <div className="col-12">
                    <AdsComponent dataAdSlot="5824067931" />
                </div>
            </div>

        </>
    )
}

export default PublicDashboardPageMaker;